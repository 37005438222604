import { CHANGE_VARIABLE } from '../actions/type';
const INITIAL_STATE = {
    
    accommodation: null,
    activity: null,
    contact: null,
    contact_image: null,
    covid: null,
    dining: null,
    experiences: null,
    form: null,
    gallery: null,
    getting_here_image: null,
    header_image: null,
    header_mobile_image: null,
    home: null,
    image: null,
    link: null,
    logo_image: null,
    logo_image_colored: null,
    newsMedia: null,
    offers: null,
    optional_image: null,
    restaurant_image: null,
    restaurant_logo: null,
    transfer_detail_image: null,
    video: null,
    water_sports: null,

}
const a = (state = INITIAL_STATE, action) =>  {
    switch (action.type) {
        case CHANGE_VARIABLE:
      		return {...state, [ action.payload.key]: action.payload.value };
        default:
            return state;
    }
}
export { a as default };
