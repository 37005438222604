import './index.css';

import {Suspense, lazy, StrictMode} from 'react';
import {render} from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import reducers from './reducers' ;
import thunk from 'redux-thunk';

const store = createStore(reducers, applyMiddleware(thunk)) ;
const App = lazy(() => import('./App'));
const rootElement = document.getElementById('root');

const main = () =>
	render(
		<Provider store={store}>
			<Suspense fallback={<div />}>
				<Router>
					<StrictMode>
						<App />
					</StrictMode>
				</Router>
			</Suspense>
		</Provider>,
		rootElement
	);

main();
